import React from "react";
import PrivacyPolicyPage from "./components/security/privacy";

export default function Security() {
  return (
    <>
      <PrivacyPolicyPage />
    </>
  );
}
