import React from 'react';

export const Logo = () => {
    return (
        <svg width="238" height="22" viewBox="0 0 238 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_220_143)">
        <path d="M75.9713 21.6508C74.2909 21.6508 72.6805 21.4383 71.1402 21.0131C69.5998 20.5687 68.3595 19.9986 67.4192 19.303L69.0696 15.7667C69.9699 16.385 71.0301 16.8971 72.2504 17.3029C73.4907 17.6894 74.741 17.8826 76.0014 17.8826C76.9616 17.8826 77.7318 17.7957 78.3119 17.6218C78.9121 17.4285 79.3522 17.1677 79.6322 16.8391C79.9123 16.5106 80.0523 16.1338 80.0523 15.7087C80.0523 15.1676 79.8323 14.7425 79.3922 14.4333C78.9521 14.1048 78.3719 13.8439 77.6517 13.6507C76.9316 13.4381 76.1314 13.2449 75.2512 13.071C74.391 12.8777 73.5207 12.6459 72.6405 12.3753C71.7803 12.1048 70.9901 11.757 70.2699 11.3318C69.5498 10.9067 68.9596 10.3463 68.4995 9.65064C68.0594 8.95498 67.8393 8.06608 67.8393 6.98394C67.8393 5.8245 68.1594 4.77135 68.7996 3.82447C69.4597 2.85828 70.44 2.09498 71.7403 1.53459C73.0606 0.954867 74.711 0.665009 76.6915 0.665009C78.0118 0.665009 79.3121 0.8196 80.5925 1.12878C81.8728 1.41864 83.0031 1.86309 83.9833 2.46213L82.4829 6.0274C81.5027 5.48633 80.5225 5.09019 79.5422 4.83898C78.562 4.56845 77.6017 4.43318 76.6615 4.43318C75.7213 4.43318 74.9511 4.53946 74.3509 4.75202C73.7508 4.96459 73.3207 5.24478 73.0606 5.59261C72.8006 5.92112 72.6705 6.3076 72.6705 6.75205C72.6705 7.2738 72.8906 7.69892 73.3307 8.02743C73.7708 8.33661 74.3509 8.58783 75.0711 8.78107C75.7913 8.97431 76.5815 9.16754 77.4417 9.36078C78.3219 9.55402 79.1921 9.77625 80.0523 10.0275C80.9326 10.2787 81.7327 10.6168 82.4529 11.042C83.1731 11.4671 83.7532 12.0275 84.1934 12.7232C84.6535 13.4188 84.8835 14.2981 84.8835 15.3609C84.8835 16.501 84.5534 17.5445 83.8933 18.4913C83.2331 19.4382 82.2429 20.2015 80.9226 20.7812C79.6222 21.361 77.9718 21.6508 75.9713 21.6508Z" fill="url(#paint0_linear_220_143)"/>
        <path d="M0 1.01284V4.83898H8.82216C10.3225 4.83898 11.4428 5.14816 12.183 5.76653C12.9232 6.3849 13.2933 7.25447 13.2933 8.37526C13.2933 9.47673 12.9232 10.3366 12.183 10.955C11.4428 11.5734 10.3225 11.8826 8.82216 11.8826H0V21.303H4.86119V15.7087H9.09223C10.9727 15.7087 12.5931 15.4188 13.9534 14.8391C15.3138 14.2401 16.364 13.3898 17.1042 12.2884C17.8444 11.1869 18.2145 9.88253 18.2145 8.37526C18.2145 6.84867 17.8444 5.53464 17.1042 4.43318C16.364 3.33171 15.3138 2.49112 13.9534 1.9114C12.5931 1.31236 10.9727 1.01284 9.09223 1.01284H0Z" fill="url(#paint1_linear_220_143)"/>
        <path d="M21.685 11.9115V21.303H26.5462V15.6507H30.7772C30.8538 15.6507 30.93 15.6502 31.0057 15.6493L35.0383 21.303H40.2896L35.5972 14.7986L35.6384 14.7812C36.9987 14.2014 38.049 13.3705 38.7892 12.2884C39.5294 11.1869 39.8995 9.88253 39.8995 8.37526C39.8995 6.84867 39.5294 5.53464 38.7892 4.43318C38.049 3.33171 36.9987 2.49112 35.6384 1.9114C34.2781 1.31236 32.6577 1.01284 30.7772 1.01284H21.685V4.83898H30.5072C32.0075 4.83898 33.1278 5.14816 33.868 5.76653C34.6082 6.3849 34.9783 7.25447 34.9783 8.37526C34.9783 9.47673 34.6082 10.3463 33.868 10.984C33.1278 11.6024 32.0075 11.9115 30.5072 11.9115H21.685Z" fill="url(#paint2_linear_220_143)"/>
        <path d="M95.1069 21.6508C93.4265 21.6508 91.8161 21.4383 90.2757 21.0131C88.7353 20.5687 87.495 19.9986 86.5548 19.303L88.2052 15.7667C89.1054 16.385 90.1657 16.8971 91.386 17.3029C92.6263 17.6894 93.8766 17.8826 95.1369 17.8826C96.0971 17.8826 96.8673 17.7957 97.4475 17.6218C98.0476 17.4285 98.4877 17.1677 98.7678 16.8391C99.0479 16.5106 99.1879 16.1338 99.1879 15.7087C99.1879 15.1676 98.9678 14.7425 98.5277 14.4333C98.0876 14.1048 97.5075 13.8439 96.7873 13.6507C96.0671 13.4381 95.2669 13.2449 94.3867 13.071C93.5265 12.8777 92.6563 12.6459 91.7761 12.3753C90.9159 12.1048 90.1257 11.757 89.4055 11.3318C88.6853 10.9067 88.0952 10.3463 87.6351 9.65064C87.1949 8.95498 86.9749 8.06608 86.9749 6.98394C86.9749 5.8245 87.295 4.77135 87.9351 3.82447C88.5953 2.85828 89.5755 2.09498 90.8758 1.53459C92.1962 0.954867 93.8466 0.665009 95.8271 0.665009C97.1474 0.665009 98.4477 0.8196 99.728 1.12878C101.008 1.41864 102.139 1.86309 103.119 2.46213L101.618 6.0274C100.638 5.48633 99.658 5.09019 98.6778 4.83898C97.6975 4.56845 96.7373 4.43318 95.7971 4.43318C94.8568 4.43318 94.0866 4.53946 93.4865 4.75202C92.8863 4.96459 92.4562 5.24478 92.1962 5.59261C91.9361 5.92112 91.8061 6.3076 91.8061 6.75205C91.8061 7.2738 92.0261 7.69892 92.4662 8.02743C92.9063 8.33661 93.4865 8.58783 94.2067 8.78107C94.9268 8.97431 95.717 9.16754 96.5772 9.36078C97.4575 9.55402 98.3277 9.77625 99.1879 10.0275C100.068 10.2787 100.868 10.6168 101.588 11.042C102.309 11.4671 102.889 12.0275 103.329 12.7232C103.789 13.4188 104.019 14.2981 104.019 15.3609C104.019 16.501 103.689 17.5445 103.029 18.4913C102.369 19.4382 101.378 20.2015 100.058 20.7812C98.7578 21.361 97.1074 21.6508 95.1069 21.6508Z" fill="url(#paint3_linear_220_143)"/>
        <path d="M107.341 1.01284H112.202V21.303H107.341V1.01284Z" fill="url(#paint4_linear_220_143)"/>
        <path d="M117.187 1.01284H121.208L129.076 13.6117L136.812 1.01284H140.803L140.863 21.303H136.302L136.275 9.12477L130.09 19.158H127.9L121.748 9.3871V21.303H117.187V1.01284Z" fill="url(#paint5_linear_220_143)"/>
        <path d="M152.448 1.01284H157.249L166.641 21.303H161.54L154.817 5.62448L148.067 21.303H143.086L152.448 1.01284Z" fill="url(#paint6_linear_220_143)"/>
        <path d="M183.92 1.01284H188.722L198.114 21.303H193.013L186.289 5.62448L179.539 21.303H174.558L183.92 1.01284Z" fill="url(#paint7_linear_220_143)"/>
        <path d="M200.293 1.01284H209.385C211.266 1.01284 212.886 1.31236 214.247 1.9114C215.607 2.49112 216.657 3.33171 217.397 4.43318C218.138 5.53464 218.508 6.84867 218.508 8.37526C218.508 9.88253 218.138 11.1869 217.397 12.2884C216.657 13.3705 215.607 14.2014 214.247 14.7812C214.233 14.787 214.219 14.7928 214.205 14.7986L218.898 21.303H213.646L209.614 15.6493C209.538 15.6502 209.462 15.6507 209.385 15.6507H205.154V21.303H200.293V11.9115H209.115C210.616 11.9115 211.736 11.6024 212.476 10.984C213.216 10.3463 213.586 9.47673 213.586 8.37526C213.586 7.25447 213.216 6.3849 212.476 5.76653C211.736 5.14816 210.616 4.83898 209.115 4.83898H200.293V1.01284Z" fill="url(#paint8_linear_220_143)"/>
        <path d="M226.417 4.83898H219.696V1.01284H238V4.83898H231.278V21.303H226.417V4.83898Z" fill="url(#paint9_linear_220_143)"/>
        <path d="M53.7968 6.93957C53.4713 6.62514 52.9436 6.62514 52.618 6.93957C52.2925 7.25401 52.2925 7.76381 52.618 8.07825L55.2239 10.5954L49.1787 10.5954C48.7184 10.5954 48.3452 10.9559 48.3452 11.4005C48.3452 11.8452 48.7184 12.2057 49.1787 12.2057L55.2239 12.2057L52.618 14.7228C52.2925 15.0373 52.2925 15.5471 52.618 15.8615C52.9436 16.1759 53.4713 16.1759 53.7968 15.8615L57.8256 11.9699C58.1511 11.6554 58.1511 11.1456 57.8256 10.8312L53.7968 6.93957Z" fill="url(#paint10_linear_220_143)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.3804 1.47017C47.6073 1.47017 42.9272 5.99094 42.9272 11.5676C42.9272 17.1442 47.6073 21.665 53.3804 21.665C59.1536 21.665 63.8337 17.1442 63.8337 11.5676C63.8337 5.99094 59.1536 1.47017 53.3804 1.47017ZM44.5943 11.5676C44.5943 6.8803 48.528 3.0805 53.3804 3.0805C58.2329 3.0805 62.1666 6.8803 62.1666 11.5676C62.1666 16.2549 58.2329 20.0547 53.3804 20.0547C48.528 20.0547 44.5943 16.2549 44.5943 11.5676Z" fill="url(#paint11_linear_220_143)"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint1_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint2_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint3_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint4_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint5_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint6_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint7_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint8_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint9_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint10_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <linearGradient id="paint11_linear_220_143" x1="-1.06053e-06" y1="11" x2="246" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0068FF"/>
        <stop offset="0.294792" stopColor="#762CE6"/>
        <stop offset="0.586458" stopColor="#9E5AFF"/>
        <stop offset="1" stopColor="#17CBF6"/>
        </linearGradient>
        <clipPath id="clip0_220_143">
        <rect width="238" height="21" fill="white" transform="translate(0 0.665009)"/>
        </clipPath>
        </defs>
        </svg>

  );
}