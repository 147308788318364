export const HamburgerIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
          fill="#0068FF"
        />
      </svg>
    );
  };



  export const AiIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17" fill="none">
        <path d="M7.08945 0.69018H10.6192L17.5243 16.1329H13.7739L8.83089 4.20007L3.86855 16.1329H0.206421L7.08945 0.69018Z" fill="#0068FF"/>
        <path d="M19.3769 0.690002L22.9508 0.690269V16.1329H19.3769V0.690002Z" fill="#0068FF"/>
      </svg>
    );
  };


  export const DevIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="16" viewBox="0 0 48 16" fill="none">
  <path d="M0.950806 3.54V0.690002H7.76509C9.39366 0.690002 10.8294 1.00429 12.0722 1.63286C13.3151 2.24715 14.2865 3.11143 14.9865 4.22572C15.6865 5.34 16.0365 6.66143 16.0365 8.19C16.0365 9.70429 15.6865 11.0257 14.9865 12.1543C14.2865 13.2686 13.3151 14.14 12.0722 14.7686C10.8294 15.3829 9.39366 15.69 7.76509 15.69H0.950806V12.84H4.42223H7.59366C8.59366 12.84 9.45795 12.6543 10.1865 12.2829C10.9294 11.8971 11.5008 11.3543 11.9008 10.6543C12.3151 9.95429 12.5222 9.13286 12.5222 8.19C12.5222 7.23286 12.3151 6.41143 11.9008 5.72572C11.5008 5.02572 10.9294 4.49 10.1865 4.11857C9.45795 3.73286 8.59366 3.54 7.59366 3.54H4.42223H0.950806Z" fill="#762CE6"/>
  <path d="M22.1045 12.9043V9.43286H29.0688V6.73286H22.1045V3.47572H29.9902V0.690002H18.6545V15.69H30.2688V12.9043H22.1045Z" fill="#762CE6"/>
  <path d="M34.6002 0.690002H30.8502L37.3216 15.69H40.7502L47.243 0.690002H43.793L39.1621 11.4035L34.6002 0.690002Z" fill="#762CE6"/>
</svg>
    );
  };


  
  export const UxIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="16" viewBox="0 0 31 16" fill="none">
        <path d="M7.11592 16.0003C4.96228 16.0003 3.27838 15.4053 2.06425 14.2155C0.85011 13.0257 0.243042 11.3269 0.243042 9.11925V0.690002H3.75537V8.99023C3.75537 10.4238 4.05167 11.4559 4.64429 12.0867C5.2369 12.7174 6.06801 13.0328 7.1376 13.0328C8.2072 13.0328 9.03831 12.7174 9.63092 12.0867C10.2235 11.4559 10.5198 10.4238 10.5198 8.99023V0.690002H13.9888V9.11925C13.9888 11.3269 13.3817 13.0257 12.1676 14.2155C10.9535 15.4053 9.26957 16.0003 7.11592 16.0003Z" fill="#9E5AFF"/>
        <path d="M15.5134 15.7422L22.0394 6.81841V9.35578L15.7953 0.690002H19.7846L24.0991 6.7324L22.4297 6.7539L26.6791 0.690002H30.495L24.2942 9.20526V6.71089L30.8636 15.7422H26.8092L22.3429 9.35578H23.9473L19.5461 15.7422H15.5134Z" fill="#9E5AFF"/>
      </svg>
    );
  };


  export const XrIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="16" viewBox="0 0 33 16" fill="none">
  <path d="M0.863586 15.9783L7.43755 6.91452V9.49168L1.14751 0.690002H5.16615L9.51239 6.82716L7.83067 6.849L12.1114 0.690002H15.9553L9.70895 9.3388V6.80532L16.3266 15.9783H12.2424L7.74331 9.49168H9.3595L4.9259 15.9783H0.863586Z" fill="#17CBF6"/>
  <path d="M19.2525 0.690002H25.8702C27.2388 0.690002 28.4182 0.915687 29.4083 1.36706C30.3984 1.80386 31.1628 2.43723 31.7016 3.26717C32.2403 4.09711 32.5097 5.0872 32.5097 6.23746C32.5097 7.37317 32.2403 8.35598 31.7016 9.18592C31.1628 10.0013 30.3984 10.6274 29.4083 11.0642C29.3983 11.0686 29.3884 11.073 29.3783 11.0773L32.7936 15.9783H28.9715L26.0365 11.7183C25.9814 11.719 25.9259 11.7194 25.8702 11.7194H22.7907V15.9783H19.2525V8.90199H25.6736C26.7656 8.90199 27.581 8.66903 28.1197 8.2031C28.6585 7.72261 28.9278 7.0674 28.9278 6.23746C28.9278 5.39297 28.6585 4.73776 28.1197 4.27183C27.581 3.8059 26.7656 3.57294 25.6736 3.57294H19.2525V0.690002Z" fill="#17CBF6"/>
</svg>
    );
  };